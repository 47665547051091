export const CONTENT_CLUSTERS = {
  'Rapid Thematic Content Clustering with Internal Linking Strategy - Standard Cluster Lite': [
    {
      contentTypeId: 211,
      type: 'Powered Up SEO Copy w/ Watson NLP Optimization - Specific Knowledge Subject',
      quantity: 10,
    },
    { contentTypeId: 76, type: 'Powered Up SEO Longform w/ Watson NLP - Basic', quantity: 1 },
    { contentTypeId: 113, type: 'Micrographic - Premium', quantity: 1 },
  ],
  'Rapid Thematic Content Clustering with Internal Linking Strategy - Standard Cluster Basic': [
    { contentTypeId: 75, type: 'Powered Up SEO Longform w/ Watson NLP - Lite', quantity: 10 },
    { contentTypeId: 77, type: 'Powered Up SEO Longform w/ Watson NLP - Premium', quantity: 1 },
    { contentTypeId: 112, type: 'Micrographic - Basic', quantity: 2 },
  ],
  'Rapid Thematic Content Clustering with Internal Linking Strategy - Premium Cluster': [
    {
      contentTypeId: 211,
      type: 'Powered Up SEO Copy w/ Watson NLP Optimization - Specific Knowledge Subject',
      quantity: 15,
    },
    { contentTypeId: 77, type: 'Powered Up SEO Longform w/ Watson NLP - Premium', quantity: 1 },
    { contentTypeId: 113, type: 'Micrographic - Premium', quantity: 1 },
    { contentTypeId: 232, type: 'Website & Social Engagement Video - Premium', quantity: 2 },
    { contentTypeId: 75, type: 'Powered Up SEO Longform w/ Watson NLP - Lite', quantity: 1 },
  ],
};

export const STEPS = [
  'Requested',
  'In Revision',
  'Edits Requested',
  'Pending Approval',
  'Pending Implementation',
  'Implemented',
];

export const STEPS_WITH_TOTAL_HEADERS = ['Pending Approval', 'Implemented'];
export const RATEABLE_STEPS = ['Pending Approval', 'Pending Implementation', 'Implemented'];

export const STEP_DB_KEY = {
  Requested: 'Requested',
  'In Revision': 'In Revision',
  'Edits Requested': 'Pending Edits',
  'Pending Approval': 'Pending Approval',
  'Pending Implementation': 'Approved',
  Implemented: 'Launched',
};

export const STEPS_MAP = Object.values(STEPS).reduce((carry, step) => {
  carry[step] = true;
  return carry;
}, {});

export const STEP_COLOR_CLASSES = {
  Requested: 'secondary',
  'In Revision': 'warning',
  'Edits Requested': 'blue-300',
  'Pending Approval': 'blue-400',
  'Pending Implementation': 'cyan',
  Implemented: 'success',
};

export const RATING_VALUES = {
  negative: {
    value: 5,
    text: 'Less Copy Like This',
    name: 'negative',
  },
  neutral: {
    value: 7,
    text: 'Neutral',
    name: 'neutral',
  },
  positive: {
    value: 10,
    text: 'More Copy Like This',
    name: 'positive',
  },
};

export const QUERY_PARAM_DEFAULTS = {
  defaults: { sort: { createdAt: -1 }, showHiddenItems: false },
  replaceKeys: ['sort'],
  transformKeys: {
    sort: '$sort',
    skip: '$skip',
    limit: '$limit',
  },
};
