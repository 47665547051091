import React from 'react';
import { v4 as uuid } from 'uuid';

import { api, ActionBar, Button, Table } from '@webfx/core-web';
import { useAuth } from '@webfx/web-hooks';

import columns from './columns';

function Tokens() {
  const user = useAuth().user;
  const userId = user?.userId || null;

  const tokens = api.useQuery(['tokens', { userId, $limit: -1 }], {
    enabled: !!userId,
  });

  const mutator = api.useMutation(['tokens']);

  const createToken = () => {
    mutator.mutate({
      _method: 'create',
      token: uuid(),
      userId,
      description: '',
    });
  };

  const updateToken = (payload) => {
    const { values } = payload;

    mutator.mutate({
      _method: 'patch',
      _id: values.tokenId,
      description: values.description,
    });
  };

  const removeToken = (payload) => {
    const { id } = payload;
    mutator.mutate({
      _method: 'delete',
      _id: id,
    });
  };

  return (
    <>
      <ActionBar>
        <Button
          variant="primary"
          text="New API Key"
          uppercase
          type="submit"
          icon="add"
          onClick={createToken}
        />
      </ActionBar>

      <Table
        columns={columns}
        data={tokens.data ?? []}
        borderlessHeader
        options={{
          createToken,
          updateToken,
          removeToken,
        }}
      />
    </>
  );
}

export default Tokens;
