import { api } from '@webfx/core-web';
import { useQueryParamFilters } from '@webfx/web-hooks';
import { QUERY_PARAM_DEFAULTS } from '@webfx/contentgenius-web/src/constants';

export default function useCopyPageStats({ companyId, siteId, forCGFX }) {
  const { query = {} } = useQueryParamFilters(QUERY_PARAM_DEFAULTS);

  // allowed filters from URL
  const { showHiddenItems } = query;

  const pageStats = api.useQuery(
    ['content/page-stats', { query: { companyId, siteId, forCGFX, showHiddenItems } }],
    {
      enabled: !!companyId || !!siteId,
      select: (data) => data.data ?? data ?? [],
      staleTime: 1000 * 60 * 5, // 5 min
      cacheTime: 1000 * 60 * 10, // 10 min
    }
  );

  return pageStats;
}
