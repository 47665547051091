// import marketing contacts config
const { adtechfxAddons } = require('@webfx/constants');
const getPlanMarketingContactsConfig = require('./get-plan-marketing-contacts-config');

/**
 * Handles finding subscription block quantity for skus - plan addons
 * @param {string} itemCode
 * @param {boolean} returnObj - default is false
 * @param {boolean} planId
 * @param {boolean} isOutdatedPPC
 * @returns {integer|object} - an integer or object
 */
function getPlanBlockQty(itemCode = null, returnObj = false, planId = null, isOutdatedPPC = false) {
  const skus = [
    {
      price: 2,
      quantity: 5,
      itemCode: 'ITEM-811',
      icon: 'phone',
      itemType: 'phoneNumbers',
      increments: '5 phone numbers',
      quantityName: 'quantityPhoneNumber',
      subtext: '5 additional phone numbers for increased offline and online channel tracking',
      text: 'CallTrackerFX Numbers',
      quantityText: null,
      limitText: 'phone numbers',
      hasPlanBase: true,
    },
    {
      price: 0,
      quantity: 0,
      itemCode: 'ITEM-900',
      icon: 'phone_callback',
      itemType: 'numberPool',
      increments: '25 phone numbers',
      quantityName: 'quantityNumberPool',
      subtext: 'Blocks of 25 numbers to enhance customer journeys and ROI reporting',
      text: 'CallTrackerFX Dedicated Number Pool',
      quantityText: 'Coming Soon',
      limitText: 'phone numbers',
      hasPlanBase: false,
    },
    {
      price: 0.15,
      quantity: 200,
      itemType: 'calls',
      itemCode: 'ITEM-812',
      icon: 'phone_in_talk',
      increments: '200 calls',
      quantityName: 'quantityAdditionalCalls',
      text: 'CallTrackerFX Monthly Calls Tracked',
      subtext: '200 additional phone calls tracked per month for more recordings and insights',
      quantityText: null,
      limitText: 'calls',
      hasPlanBase: true,
    },
    {
      price: 10,
      quantity: 5,
      icon: 'search',
      itemCode: 'ITEM-813',
      increments: '5 reports',
      itemType: 'deeplearningfx',
      quantityName: 'quantityContentReports',
      subtext: '5 additional reports per month to achieve 8% stronger content marketing results',
      text: 'DeepLearningFX SEO Content Reports',
      quantityText: null,
      limitText: 'reports',
      hasPlanBase: true,
    },
    {
      text: 'EmailMarketingFX Overages',
      minimumPlanLevel: 1,
      subtext:
        'Allow EmailMarketingFX to continue sending emails after your monthly contact limit is reached. By continuing to send, you will receive an invoice for overages at the beginning of the following month. If overages are enabled, you may select a maximum contact quantity to prevent overage from exceeding your desired amount.',
      icon: 'markunread_mailbox',
      itemType: 'emfxOverages',
      itemCode: {
        1: 'ITEM-1039',
        2: 'ITEM-1040',
        3: 'ITEM-1041',
        4: 'ITEM-1042',
        5: 'ITEM-1044',
        6: 'ITEM-1043',
      }[planId || 1],
      hasPlanBase: true,
      quantityName: 'quantityEmfxOverages',
      limitText: 'email contacts/month',
      quantityText: 'Maximum overage amount:',
      price: 60,
      quantity: 1,
    },
    {
      price: 10,
      quantity: 5,
      icon: 'find_in_page',
      itemCode: 'ITEM-634',
      itemType: 'reviewLocations',
      quantityName: 'quantity',
      increments: '5 locations',
      text: 'ReviewAccelerationFX Locations',
      subtext:
        '5 additional locations to drive more local leads and customer experience visibility',
      quantityText: null,
      limitText: 'locations',
      hasPlanBase: true,
    },
    {
      price: 50,
      quantity: 3,
      icon: 'nat',
      itemCode: 'ITEM-853',
      itemType: 'integrationsFx',
      increments: '3 connectors',
      quantityName: 'quantityConnectors',
      text: 'IntegrationsFX Connectors',
      subtext: '3 additional connecters for data unification to drive ROI performance',
      quantityText: null,
      limitText: 'additional 3rd party software data pipelines',
      hasPlanBase: true,
    },
    {
      price: 50,
      quantity: 15,
      itemCode: 'ITEM-1072',
      text: 'Visitor-Level Tracking via Dedicated Number Pool',
      subtext:
        'Enables option to use dedicated number pool to enhance customer journeys and ROI reporting (Typically allocated to Paid Advertising Channels, for Campaign and Ad performance visibility). Comes with 15 additional call tracking numbers.',
      icon: 'contact_phone',
      itemType: 'visitorLevelTracking',
      quantityName: 'quantityVistorLevelTracking',
      increments: '15',
    },
    ...(planId !== 5
      ? [
          {
            price: 8,
            quantity: 1,
            icon: 'calendar_today',
            itemCode: 'ITEM-5003',
            itemType: 'schedulerfxUsers',
            increments: '1 user',
            quantityName: 'quantitySchedulerfxUsers',
            text: 'SchedulerFX Users',
            subtext: 'Get additional users for streamlined calendar bookings',
            quantityText: null,
            limitText: 'users',
            hasPlanBase: true,
          },
        ]
      : []),
    ...(planId !== 5
      ? [
          {
            price: (() => {
              switch (planId) {
                case 1:
                case 2:
                case 3:
                  return 39;
                case 4:
                case 6:
                  return 63;
                default:
                  return null;
              }
            })(),
            quantity: 1,
            icon: 'group',
            itemCode: planId === 3 ? 'ITEM-1045' : 'ITEM-1046',
            itemType: 'nutshell',
            increments: '1 Nutshell user',
            quantityName: 'quantityNutshellUsers',
            text: 'Nutshell Users',
            subtext: 'Additional users for NutshellCRM & all of its tools and features!',
            quantityText: null,
            limitText: 'Nutshell users',
            hasPlanBase: true,
            minimumPlanLevel: 1,
          },
        ]
      : []),
  ];

  if (planId) {
    const mcPlanConfig = getPlanMarketingContactsConfig(planId);
    if (mcPlanConfig?.itemCode) {
      // add text description
      if (!mcPlanConfig?.text) {
        mcPlanConfig.text = 'Marketing Contacts';
      }

      skus.push(mcPlanConfig);
    }
  }

  if (adtechfxAddons.enterprise.includes(itemCode)) {
    if (!(itemCode === 'ITEM-935' || itemCode === 'ITEM-938')) {
      skus.push({
        quantityName: 'quantityAdTechPPC',
        itemCode,
        itemType: 'adtechPPC',
        text: `Pro PPC plan`,
        price: 0,
      });
    }
    if (!isOutdatedPPC) {
      skus.push(
        {
          quantityName: 'quantityMonth1AdTechPPC',
          itemCode: 'ITEM-935',
          itemType: 'adtechPPC',
          text: `Pro PPC plan Month 1 Setup`,
          price: 0,
        },
        {
          quantityName: 'quantityMonth1AdTechPPC',
          itemCode: 'ITEM-938',
          itemType: 'adtechPPC',
          text: `Pro PPC plan Month 2 Optimization`,
          price: 0,
        }
      );
    }
  }

  if (itemCode) {
    const hasItemCode = skus.filter((v) => v.itemCode === itemCode);

    if (hasItemCode && hasItemCode.length > 0) {
      if (returnObj) {
        return hasItemCode[0];
      }

      return hasItemCode[0].quantity;
    }
  }

  if (!itemCode && returnObj) {
    return skus;
  }

  return 0;
}

module.exports = getPlanBlockQty;
