import { useAuth } from '@webfx/web-hooks';
import { api } from '@webfx/core-web';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { email as emailSchema } from '../utils/validators';

const validationSchema = Yup.object().shape({
  email: emailSchema,
});

export function useLogin(mutatorOptions = {}) {
  const login = useAuth().login;
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(false);
  const [storedEmail, setStoredEmail] = useState('');

  const mutator = api.useMutation(['sign-in/generate'], {
    ...mutatorOptions,
    onSuccess: (data) => {
      mutatorOptions.onSuccess?.(data);
      return true;
    },
  });

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const code = params.get('code');
  const provider = params.get('provider');

  const form = {
    initialValues: {
      email: '',
    },
    validationSchema,

    onSubmit: async (values, actions) => {
      setDisabled(true);
      actions.setSubmitting(true);
      actions.setStatus(null);
      setError(null);
      setStoredEmail(values.email);

      maybeForceGoogleAuth(values.email);
      await mutator.mutate(
        {
          _method: 'create',
          ...values,
          email: values.email || form.initialValues.email,
        },
        {
          onError: (error) => {
            const message = extractErrorMessage(error);
            actions.setStatus(message);
            setError(message);
          },
          onSettled: () => {
            actions.setSubmitting(false);
            setDisabled(false);
          },
        }
      );
    },
    displayName: 'Sign In Form',
  };

  const handleOffice365Login = useCallback(
    async (code) => {
      setDisabled(true);

      try {
        await login({ strategy: 'office365', code });
      } catch (error) {
        setError(error.message);
        setDisabled(false);
      }
    },
    [login, setDisabled, setError]
  );

  useEffect(() => {
    if (code && provider === 'office365') {
      handleOffice365Login(code);
    }
  }, [code, handleOffice365Login, provider]);

  const resendEmail = async () => {
    try {
      setDisabled(true);
      setError(null);

      await mutator.mutate(
        {
          _method: 'create',
          email: storedEmail,
        },
        mutatorOptions
      );

      return true;
    } catch (error) {
      const message = extractErrorMessage(error);
      setError(message);
      return false;
    } finally {
      setDisabled(false);
    }
  };

  const handleTokenLogin = async (token) => {
    try {
      setDisabled(true);
      setError(null);
      await login({ strategy: 'sign-in-token', token });
    } catch (error) {
      const message = extractErrorMessage(error);
      setError(message);
      console.error('Error logging in with token', error, message);
    } finally {
      setDisabled(false);
    }
  };

  return {
    form,
    error,
    disabled,
    setError,
    setDisabled,
    isLoading: mutator.isLoading,
    isSuccess: mutator.isSuccess,
    resendEmail,
    handleTokenLogin,
  };
}

function extractErrorMessage(error) {
  const extractedErr = error.message || error;

  switch (extractedErr) {
    case 'No email':
      return 'Please enter an email address.';
    case 'Invalid email':
      return 'No account has been found for the provided email address.';
    case 'Invalid ip address':
      return 'This user cannot access the server from this IP address.';
    case 'Multiple users found':
      return 'Multiple users found with the same email address.';
    case 'Admin login':
      return 'Please login via Google Single Sign-On.';
    case 'No token':
    case 'Invalid token':
    case 'Token expired':
      return 'Token is invalid or has expired. Please enter your email and try again.';
    case 'No active company found for this email.':
    case 'No active account found for this email.':
      return 'Your account is not active. If this is an error, please contact your account manager.';
    case 'Sign in using Google':
    case 'Organization requires that you use an SSO Provider to login.':
    case 'No active account found for this email.':
      return extractedErr;
    case 'Failed to send email':
      return 'Failed to send email, please try again or contact your account manager.';
    default:
      return 'Unable to login at this time, please contact your account manager.';
  }
}

function maybeForceGoogleAuth(email) {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }
  const [username, domain] = email.split('@');
  if (domain === 'webfx.com' && !username.includes('+')) {
    throw new Error('Sign in using Google');
  }
}
