const { isObject, isString } = require('lodash');

/**
 *
 * @param {*} query
 * @returns
 */
function getTagValues(query = {}) {
  return Object.entries(query).reduce((_acc, [key, tags]) => {
    if (!tags) {
      return _acc;
    }
    const acc = _acc;
    const operator = query?.excludes && query?.excludes['$in'].includes(key) ? '$nin' : '$in';
    if (tags.value) {
      acc[key] = Array.isArray(tags.value) ? tags.value : { [operator]: [tags.value] };
    }
    if (Array.isArray(tags)) {
      const tagValues = tags.map((t) => t.value || t);
      acc[key] = tagValues;
    }
    if (tags['$in']) {
      const tagValues = tags['$in'].map((tag) => {
        if (Array.isArray(tag)) {
          return { [operator]: tag.map((t) => t.value || t) };
        }
        return tag.value || tag;
      });
      acc[key] = { [operator]: tagValues };
    } else if (isObject(tags) && (tags?.gte || tags?.lte)) {
      acc[key] = {
        ...(tags.gte ? { $gte: tags.gte } : {}),
        ...(tags.lte ? { $lte: tags.lte } : {}),
        $relation: 'WITHIN',
      };
    }

    if (acc[key]?.$in) {
      acc[key].$in = acc[key]?.$in?.map((value) => (isString(value) ? value.toLowerCase() : value));
      if (acc[key]?.$in?.some((v) => v === 'select_all')) {
        delete acc[key]; // if select_all is present, remove the key from the query since we don't want to filter by it
      }
    }
    return acc;
  }, {});
}

module.exports = getTagValues;
